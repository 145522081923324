import React from 'react';
//import { Link } from 'gatsby';
import Editor  from "../components/Editor";
import Layout from "../components/layout"


function prova(e,b,c){

  //var magic = document.getElementById("magic");
  console.log('magiiicooo');
  console.log(e.pageY);
  if(e.pageY<400){
    document.getElementById("magic").style.left = (e.pageX-230)+'px';
    document.getElementById("magic").style.top = (e.pageY-230)+'px';
  }

  //$magic.css({"left": e.pageX - magicWHalf, "top": e.pageY - magicWHalf});

}




export default ({ pageContext: { room , lang } }) => (
  <Layout lang={lang}>
    <div>
      <div style={{/*background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9)) ,url('+room.copertina+') 50% 50% no-repeat fixed'*/backgroundColor:'rgb(245, 241, 234)',backgroundSize: 'cover'}} onMouseMove={(e, setIsHovering, unsetIsHovering)=>prova(e,setIsHovering, unsetIsHovering)} class="scene">
        <h1 style={{color:'black',fontSize:50,fontWeight: 'lighter'}}>{room.title}</h1>
      <div style={{ border: '3px solid',boxShadow:' 2px 3px 3px 3px #e6e4e1',background: 'url('+room.copertina+') 50% 50% no-repeat fixed',backgroundSize: 'cover'}} id='magic' class="magic"></div>

      </div>
      <div className='graphicObj' style={{position:'absolute',marginLeft: '10%', height: '100%'}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="810" height="394" fill="none"><path d="M142.15 1C23.022 64.953-161.726 285.115 52.305 654.138 266.336 1023.16 645.948 1300.47 809 1393" stroke="#262627"/></svg>
      </div>

      {/*<section id='imgBig' style={{display: 'table',backgroundImage: 'linear-gradient(0deg,rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)),url('+room.copertina+')', height: 400, width: '100%'}}>
        <div style={{verticalAlign: 'middle',display: 'table-cell'}}>
          <div id='testoImgBig'>
            <h1>{room.title}</h1>
            <p>sub</p>
          </div>
        </div>
      </section>*/}
      <div className='infoBox'>
        <div className='infoBoxIn'>

          <div style={{background:'rgb(236, 210, 149)',padding:10}}>
            <p>Capienza</p>
          <h3>{room.capacita} {room.capacita > 1 ? 'Persone' : 'Persona'}</h3>
          </div>
          <div style={{background:'rgb(236, 210, 149)',padding:10}}>
           {/*<p className='buttonHover' style={{padding:10,marginTop:25}}><a target='_blank' href='https://redirect.fastbooking.com/DIRECTORY/dispoprice.phtml?showPromotions=1&Clusternames=ITMILHTLDemidoffHote&Hotelnames=ITMILHTLDemidoffHote'>BOOK NOW</a></p>*/}
          </div>
        </div>
      </div>

      <div style={{ maxWidth: 770, margin: '4rem auto', marginTop: -61,background: '#ffffff',position: 'relative' }}>
        {/*<Editor readOnly={true} body={ lang == 'en' ? room.body : JSON.parse(room.body)} />*/}
        <Editor readOnly={true} body={ lang === 'en' ? JSON.parse(room.bodyen) : JSON.parse(room.body)} />
        {/*lang == 'en' ? room.bodyen : room.body*/}
      </div>
    </div>
  </Layout>
);
